<template>
    <v-app>
      <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
  
          <v-icon large color="blue-grey darken-2">mdi-lock-reset</v-icon>
          <v-subheader>Password Reset</v-subheader>
          
          <v-alert v-if="resetMessage" :type="resetMessageType">{{ resetMessage }}</v-alert>
  
          <v-text-field
            label="Email"
            v-model="email"
            :rules="emailRules"
            required
          ></v-text-field>
  
          <v-btn @click="startPasswordReset" :disabled="!valid" color="primary">Send Reset Email</v-btn>
          
          <!-- Button to navigate back to the login page -->
          <br />
          <v-btn @click="goToLogin" class="mt-3">Back to Login</v-btn>
  
        </v-form>
      </v-container>
    </v-app>
  </template>
  
<script>
// Import Firebase Auth (Modular SDK v10.1.0)
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

export default {
    data: () => ({
        valid: true,
        email: "",
        resetMessage: "",
        resetMessageType: "success",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
    }),
    methods: {
        async startPasswordReset() {
            const auth = getAuth();
            try {
                await sendPasswordResetEmail(auth, this.email);
                this.resetMessage = "Check your email for a link to reset your password.";
                this.resetMessageType = "success";
            } catch (error) {
                this.resetMessage = "Error sending password reset email. Please try again.";
                this.resetMessageType = "error";
                console.error("Error sending password reset email: ", error);
            }
        },
        // Method to navigate back to the login page
        goToLogin() {
            this.$router.push('/login');
        },
    },
};
</script>
  